import { CryptoHelper } from './containers/helper/Crypto/Crypto';
let accessToken = "";

export const setAccessToken = (s: string) => {
  accessToken = s;
};

export const getAccessToken = () => {
  if(!accessToken){
    accessToken = CryptoHelper.decryption(localStorage.getItem("key")??"") ?? "";
  }
  return accessToken;
};
export const ClearAccessToken = () => {
  accessToken=''
  localStorage.removeItem("key")
 };

