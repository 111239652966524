import { Suspense } from 'react'
import { Navigate, Outlet } from 'react-router'
import { getAccessToken } from '../../AccessToken'
import { useLocation } from 'react-router-dom'
import { Spinner } from '@ifca-ui/core'

export const AuthGuard = () => {
  const { pathname } = useLocation()
  const isLoginPage = pathname === '/login'
  const AccessToken = getAccessToken()

  if (isLoginPage && AccessToken) {
    const url = '/'
    return <Navigate to={url} replace />
  }

  if (AccessToken) {
    return (
      <Suspense fallback={<Spinner />}>
        <Outlet />
      </Suspense>
    )
  } else {
    return <Navigate to="/login" replace />
  }
}
