let loanDetail = '';
let name = '';
let email = '';
let url = '';

export const setLoanDetail = (s: string) => {
    loanDetail = s;
};

export const getLoanDetail = () => {
    return loanDetail;
};


export const setName = (s: string) => {
    name = s;
};

export const getName = () => {
    return name;
};

export const setEmail = (s: string) => {
    email = s;
};

export const getEmail = () => {
    return email;
};

export const setUrl = (s: string) => {
    url = s;
};

export const getUrl = () => {
    return url;
};

export const reset = () => {
    loanDetail = ''
    name = ''
    url = ''
}