import { HeaderProps } from '@ifca-ui/core'
import { Reducer } from 'react'
import { Action } from './Types'

export interface RootStateProps {
  isDrawerOpen?: boolean
  subHeaderHeight?: number
  headerHeight?: number
  headerComponent?: HeaderProps
  snackBar?: {
    open?: boolean
    message?: string
  }
  loading?: boolean
}
export const RootInitialState: RootStateProps = {
  isDrawerOpen: true,
  subHeaderHeight: 0,
  headerHeight: 0,
  headerComponent: {
    leftIcon: {
      icon: 'menu',
    },
    rightIcon: {
      // icon:
    },
    topSection: {
      smTitle: '',
      title: '',
    },
  },
  snackBar: {
    open: false,
    message: '',
  },
  loading: false,
}
export const RootReducer: Reducer<RootStateProps, Action> = (state, action) => {
  switch (action.type) {
    case 'reset':
      return RootInitialState
    default:
      return { ...state, [action.type]: action.payload }
  }
}
