import {
  DesktopHeader,
  Drawer,
  Header,
  layoutClasses,
  LayoutRoot,
  ModuleSection,
  ProfileMenuItem,
  ProfileSection,
  Spinner,
} from '@ifca-ui/core'
import {
  Home as HomeIcon,
  Logout as LogoutIcon,
  Profile as ProfileIcon,
} from '@ifca-ui/icons/dist/index.esm'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import PeopleIcon from '@mui/icons-material/People'
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import SideMenuBg from 'assets/images/product/sidemenu-background.svg'
import clsx from 'clsx'
import AppContext, { AppContextProps } from 'containers/context/Context'
import { ACCT_PATH } from 'containers/modules/Account/Routes'
import { AUTH_PATH } from 'containers/modules/Authentication/Routes'
import { BANKER_PATH } from 'containers/modules/Banker/Routes'
import {
  useFinancierNameLazyQuery,
  useProfileLazyQuery,
} from 'generated/graphql'
import * as React from 'react'
import { NavLink, useNavigate, useLocation, Outlet } from 'react-router-dom'
import {
  getLoanDetail,
  setLoanDetail,
  setName,
  getName,
  setEmail,
  getEmail,
  setUrl,
  getUrl,
  reset,
} from 'userDetail'
import { HOME_PATH } from 'containers/modules/Home/Routes'
import { navigationService } from 'navigationService'
import { setAccessToken } from 'AccessToken'
import { Suspense } from 'react'
import HeaderX from 'components/HeaderX'
// import headerBgURL from 'assets/images/header/header-bg.svg'
interface Modules {
  name?: string
  path?: string
  icon?: any
  iconActived?: any
}
interface MainLayoutProps {
  children?: React.ReactNode
  window?: () => Window
}
const MainLayout: React.FC<MainLayoutProps> = ({ children, window }) => {
  const nav = new navigationService()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [role, setRole] = React.useState('')
  const { rootState, rootDispatch } =
    React.useContext<AppContextProps>(AppContext)
  const ModulesListWithBanker: Modules[] = [
    {
      name: 'Home',
      path: HOME_PATH.HOME,
      icon: <HomeIcon className="svg-icon" />,
      iconActived: <HomeIcon className="svg-icon" />,
    },
    {
      name: 'Banker',
      path: BANKER_PATH.BANKER_LIST,
      icon: <PeopleIcon color="primary" />,
      iconActived: <PeopleIcon className="svg-icon" color="primary" />,
    },
    {
      name: 'Notification',
      path: '/permission',
      icon: <NotificationsActiveIcon color="primary" />,
      iconActived: (
        <NotificationsActiveIcon className="svg-icon" color="primary" />
      ),
    },
    {
      name: 'Log Out',
      path: '/logout',
      icon: <LogoutIcon className="svg-icon" />,
      iconActived: <LogoutIcon className="svg-icon" />,
    },
  ]
  const ModulesListWithoutBanker: Modules[] = [
    {
      name: 'Home',
      path: HOME_PATH.HOME,
      icon: <HomeIcon className="svg-icon" />,
      iconActived: <HomeIcon className="svg-icon" />,
    },
    {
      name: 'Notification',
      path: '/permission',
      icon: <NotificationsActiveIcon color="primary" />,
      iconActived: (
        <NotificationsActiveIcon className="svg-icon" color="primary" />
      ),
    },
    {
      name: 'Log Out',
      path: '/login',
      icon: <LogoutIcon className="svg-icon" />,
      iconActived: <LogoutIcon className="svg-icon" />,
    },
  ]

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const theme = useTheme()
  const container =
    window !== undefined ? () => window().document.body : undefined
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const profileMenu = (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <ProfileMenuItem
        onClick={() => {
          if (!isDesktop) {
            handleDrawerOpen()
          }
          navigate(ACCT_PATH.PROFILE)
          handleClose()
        }}
      >
        <ProfileIcon width="18px" height="18px" />
        Profile
      </ProfileMenuItem>
      <ProfileMenuItem onClick={() => clickLogout()}>
        {' '}
        <LogoutIcon width="18px" height="18px" />
        Logout
      </ProfileMenuItem>
    </Menu>
  )
  const handleDrawerOpen = () =>
    rootDispatch({ type: 'isDrawerOpen', payload: !rootState.isDrawerOpen })
  React.useEffect(() => {
    if (isDesktop && !rootState.isDrawerOpen) {
      document?.getElementById('main-header')?.classList.add('full-width')
      document
        ?.getElementById('subheader-container')
        ?.classList.add('full-width')
      document?.getElementById('footer')?.classList.add('full-width')
    } else {
      document?.getElementById('main-header')?.classList.remove('full-width')
      document
        ?.getElementById('subheader-container')
        ?.classList.remove('full-width')
      document?.getElementById('footer')?.classList.remove('full-width')
    }
  }, [rootState.isDrawerOpen, isDesktop])

  const ErrorPages = [
    {
      label: '404',
      path: AUTH_PATH.NOT_FOUND,
    },
  ]

  const [authOpen, setAuthOpen] = React.useState(false)
  const [acctOpen, setAcctOpen] = React.useState(false)
  const [errorOpen, setErrorOpen] = React.useState(false)
  const [componentOpen, setComponentOpen] = React.useState(true)
  const handleAcctClick = () => {
    setAcctOpen(!acctOpen)
  }
  const handleAuthClick = () => {
    setAuthOpen(!authOpen)
  }
  const handleErrorClick = () => {
    setErrorOpen(!errorOpen)
  }
  const handleComponentClick = () => {
    setComponentOpen(!componentOpen)
  }

  const [getLoanName, { data }] = useFinancierNameLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data?.getEndfinFinancierName) {
        setLoanDetail(data?.getEndfinFinancierName)
      }
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  const [
    getProfileName,
    {
      data: { profile: profile } = {
        profile: null,
      },
    },
  ] = useProfileLazyQuery({
    fetchPolicy: 'no-cache',
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
    onCompleted: () => {
      setName(profile?.name)
      setEmail(profile?.email)
      profile.photoUrl !== null ? setUrl(profile?.photoUrl) : setUrl(' ')
      sessionStorage.setItem('UserRole', profile?.user?.endfinUserRoles?.role)
    },
  })

  let ModulesList =
    sessionStorage.getItem('UserRole') === 'STAFF'
      ? ModulesListWithoutBanker
      : ModulesListWithBanker

  React.useEffect(() => {
    if (getName() === '' || getEmail() === '' || getUrl() === '') {
      getProfileName()
    }
  }, [getName() === '', getEmail() === '', getUrl() === ''])

  React.useEffect(() => {
    if (getLoanDetail() === '') {
      getLoanName()
    }
  }, [getLoanDetail() === ''])

  function clickLogout() {
    localStorage.clear()
    sessionStorage.clear()
    nav.clearArray()
    reset()
    navigate('/login')
  }

  function handle(ob) {
    if (!isDesktop) {
      handleDrawerOpen()
    }
    if (ob.currentTarget.id === 'Home') {
      nav.backHome()
    }
    if (ob.currentTarget?.id === 'Log Out') {
      // reset()
      // sessionStorage.clear()
      // nav.clearArray()
      // setAccessToken(null)
      clickLogout()
    }
    if (
      ob.currentTarget?.id === 'Banker' ||
      ob.currentTarget?.id === 'Notification'
    ) {
      nav.clickSideBar(ob.currentTarget.id)
    }
  }

  return (
    <LayoutRoot component="div" className={layoutClasses.root} display="flex">
      {isDesktop ? (
        <DesktopHeader
          title={getLoanDetail()}
          leftIcon={{
            props: {
              onClick: () => handleDrawerOpen(),
            },
          }}
        />
      ) : null}

      <HeaderX isDesktop={isDesktop} />
      <Drawer
        container={container}
        onClose={handleDrawerOpen}
        open={isDesktop ? rootState.isDrawerOpen : !rootState.isDrawerOpen}
        variant={isDesktop ? 'persistent' : 'temporary'}
      >
        <ProfileSection
          drawerImage={SideMenuBg}
          avatar={{
            src: getUrl(),
          }}
          profile={{
            title: `${getName()}`,
            subTitle: `${getEmail()}`,
          }}
          dropDownIcon={{
            onClick: e => handleClick(e),
          }}
        />
        <ModuleSection as="div">
          <List disablePadding>
            {ModulesList.map((module, index) => (
              <ListItem
                id={module.name}
                key={index}
                dense
                divider
                component={NavLink}
                to={module.path}
                onClick={e => handle(e)}
              >
                <ListItemIcon>
                  {pathname !== module.path && module.icon}
                  {pathname === module.path && module.iconActived}
                </ListItemIcon>
                <ListItemText
                  primary={module.name}
                  primaryTypographyProps={{
                    variant: 'inherit',
                  }}
                />
              </ListItem>
            ))}
          </List>
        </ModuleSection>
      </Drawer>
      {profileMenu}
      <Box
        component="main"
        className={clsx(layoutClasses.mainContent, {
          [layoutClasses.contentShift]: rootState.isDrawerOpen,
        })}
      >
        <Suspense fallback={<Spinner />}>
          <div style={{ height: `${rootState.headerHeight}px` }} />
          <Outlet />
        </Suspense>
      </Box>
    </LayoutRoot>
  )
}
export default MainLayout
