import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
const Dashboard = lazy(() => import('./Dashboard'))
const LoanSummary = lazy(() => import('./LoanSummary'))
const LoanList = lazy(() => import('./LoanList'))
const LoanDetail = lazy(() => import('./LoanDetail'))
const NotificationListing = lazy(() => import('./Notification'))
const WithdrawnLoanList = lazy(() => import('./WithdrawLoanList'))
const ActiveLoanList = lazy(() => import('./ActiveLoanList'))

export const HOME_PATH = {
  ROOT: '/',
  HOME: '/home',
  LOAN_SUMMARY: '/home/loan/summary',
  LOAN_LIST: '/home/loan/list',
  LOAN_DETAIL: '/home/loan/detail',
  ACTIVE_LOAN_LIST: '/home/loan/activelist',
  WITHDRAWN_LOAN_LIST: '/home/loan/withdrawnlist',
  NOTIFICATION_LISTING: 'notification/list',
}
const HomeRoutes: RouteObject[] = [
  {
    path: '',
    element: <Dashboard />,
  },
  {
    path: 'home',
    element: <Dashboard />,
  },
  {
    path: `${HOME_PATH.LOAN_SUMMARY}/:projectId`,
    element: <LoanSummary />,
  },
  {
    path: `${HOME_PATH.LOAN_LIST}/:projectId`,
    element: <LoanList />,
  },
  {
    path: `${HOME_PATH.WITHDRAWN_LOAN_LIST}/:projectId`,
    element: <WithdrawnLoanList />,
  },
  {
    path: `${HOME_PATH.ACTIVE_LOAN_LIST}/:projectId`,
    element: <ActiveLoanList />,
  },
  {
    path: `${HOME_PATH.LOAN_DETAIL}/:projectId/:loanId`,
    element: <LoanDetail />,
  },
  {
    path: HOME_PATH.NOTIFICATION_LISTING,
    element: <NotificationListing />,
  },
]

export default HomeRoutes
