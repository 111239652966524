import MainLayout from 'containers/app/MainLayout'
import AcctRoutes from 'containers/modules/Account/Routes'
import AuthRoutes, { AUTH_PATH } from 'containers/modules/Authentication/Routes'
import BankerRoutes from 'containers/modules/Banker/Routes'
// import ComponentRoutes from 'containers/modules/ComponentPage/Routes'
import HomeRoutes from 'containers/modules/Home/Routes'
import NotificationRoutes from 'containers/modules/Notification/Routes'
import { Navigate, useRoutes } from 'react-router-dom'
import { AuthGuard } from './AuthGuard'
import { Blank } from './Blank'

// //! Redirect to Home page
// const Root = [
//   {
//     props: { exact: true, path: '/' },
//     component: <Navigate to="/" />,
//   },
// ]

// //! Redirect to 404 page
// const Error = [
//   {
//     component: <Navigate replace to={'/404-not-found'} />,
//   },
// ]
//! Error component must be at the last item in an array.
// const RootRoutes = [
//   ...HomeRoutes,
//   ...BankerRoutes,
//   ...NotificationRoutes,
//   ...AcctRoutes,
//   ...ComponentRoutes,
//   ...Root,
//   ...Error,
// ]
// export default RootRoutes

export function MainRoutes() {
  const RootRoutes = useRoutes([
    {
      path: '',
      element: <AuthGuard />,
      children: [
        {
          path: '',
          element: <MainLayout />,
          children: [
            {
              path: '',
              element: <Navigate to="/home" />
            },
            {
              path: 'profile',
              children: [...AcctRoutes],
            },
            {
              path: 'home',
              children: [...HomeRoutes]
            },
            {
              path: 'banker',
              children: [...BankerRoutes]
            },
            {
              path: 'permission',
              children: [...NotificationRoutes]
            // },
            // {
            //   path: 'notification',
            //   children: [...ComponentRoutes]
            }
          ],
        },
      ],
    },
    {
      path: '',
      element: <Blank />,
      children: [
        ...AuthRoutes
      ],
    },
    {
      path: '*',
      element:<Navigate replace to={AUTH_PATH.NOT_FOUND}></Navigate>
    },
  ])
return RootRoutes
}